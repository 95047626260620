import budew from '../images/shinybudew.jpg'

function One() {
    return (
      <div className="one">
        1
        <img src={budew} alt='budew'></img>
      </div>
    );
  }
  
  export default One;
