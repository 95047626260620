function NoPage() {
    return (
      <div className="NoPage">
        404
      
      </div>
    );
  }
  
  export default NoPage;
  