import { Outlet, Link } from "react-router-dom";

function Navigator() {
    return (
        <>
            <nav>
                <ul>
                    <li>
                        <Link to="/">1st</Link>
                    </li>
                    <li>
                        <Link to="/2">2nd</Link>
                    </li>
                </ul>
            </nav>
            <Outlet></Outlet>
        </>
    )
}

export default Navigator