import './App.css';
import One from './components/one'
import Two from './components/two';
import NoPage from './components/nopage';
import Navigator from './components/navigator';
import { Routes, Route } from "react-router-dom";

function App() {
  return (
    <>
      <Navigator></Navigator>
      <Routes>
        <Route path='/' element={<One />} />
        <Route path='/2' element={<Two />} />
        <Route path='/*' element={<NoPage />} />
      </Routes>
    </>
  );
}

export default App;
