import roselia from '../images/shinyroselia.png'

function Two() {
    return (
      <div className="two">
        2
        <img src={roselia} alt='roselia'></img>
      </div>
    );
  }
  
  export default Two;
  